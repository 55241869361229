import { type FirebaseApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';

const getPushToken = async (firebaseApp: FirebaseApp) => {
  const supported = await isSupported();
  if (!supported) {
    console.warn('Firebase Messaging не поддерживается в этом браузере.');
    return null;
  }

  if (typeof window === 'undefined' || !('serviceWorker' in navigator)) {
    console.warn('Service workers не поддерживаются в этом браузере.');
    return null;
  }

  const permission = Notification.permission;
  if (permission === 'granted') {
    const messaging = getMessaging(firebaseApp);
    const currentToken = await getToken(messaging);

    if (currentToken) {
      return currentToken;
    } else {
      throw new Error(
        'No registration token available. Request permission to generate one.'
      );
    }
  }

  return null;
};

export default getPushToken;
