import cx from 'classnames';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { getScrollbarWidth } from 'application/utils/get-scrollbar-width';
import { Flex } from 'ui/flex';
import { CloseIcon } from 'ui/icons';

import css from './modal.module.scss';
import useWindowDimensions from '../../application/hooks/use-window-dimensions';
import PromoDesktopBanner from '../../features/signup/promo-banner-desktop-size.svg';
import PromoMobileBanner from '../../features/signup/promo-banner-mobile-size.svg';

const ModalContent: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <div className={css.Content}>{children}</div>;

const ModalFooter: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => (
  <div className={cx(className, css.Footer)}>{children}</div>
);

interface ModalProps {
  title: string | React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  width?: number;
  noOverflow?: boolean;
  children: React.ReactNode;
  promoBanner?: string;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  title,
  width,
  onClose,
  children,
  noOverflow,
  promoBanner,
}) => {
  const scrollbarWidth = getScrollbarWidth();
  const { width: windowWidth } = useWindowDimensions();

  const isMobile = windowWidth <= 375;

  useEffect(() => {
    const body = document.body;
    const hasScrollbar =
      window.innerHeight < document.documentElement.scrollHeight;

    if (isOpen) {
      if (hasScrollbar) {
        body.style.paddingRight = `${scrollbarWidth}px`;
      }
      body.classList.add(css.BodyWrapped);
    } else {
      body.style.paddingRight = '';
      body.classList.remove(css.BodyWrapped);
    }

    return () => {
      body.style.paddingRight = '';
      body.classList.remove(css.BodyWrapped);
    };
  }, [isOpen, scrollbarWidth]);

  if (!isOpen) {
    return null;
  }

  if (typeof window !== 'undefined') {
    return ReactDOM.createPortal(
      <div className={css.Root}>
        <div
          className={noOverflow ? css.NoOverflow : css.Overflow}
          onClick={onClose}
        />
        <div
          className={css.Panel}
          style={width ? { maxWidth: `${width}px` } : {}}
        >
          {promoBanner && (
            <div className={css.PromoWrapper}>
              <div className={css.PromoImage}>
                {isMobile ? <PromoMobileBanner /> : <PromoDesktopBanner />}
              </div>
              <div className={css.PromoText}>
                <span>Конкурс</span> на 1500$
              </div>
            </div>
          )}
          <Flex className={css.TitleWrapper} justifyContent="space-between">
            <div className={css.Title}>{title}</div>
            {onClose && (
              <div className={css.Close} onClick={onClose}>
                <CloseIcon />
              </div>
            )}
          </Flex>
          {children}
        </div>
      </div>,
      document.body
    );
  }

  return null;
};

export { ModalContent, ModalFooter };
export default Modal;
