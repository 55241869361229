import { type FirebaseApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';

const retrievePushToken = async (firebaseApp: FirebaseApp) => {
  const messagingSupported = await isSupported();

  if (!messagingSupported) {
    console.warn('Push notifications are not supported in this browser.');
    return null;
  }

  if (typeof window === 'undefined' || !('serviceWorker' in navigator)) {
    console.warn('Service workers are not supported in this browser.');
    return null;
  }

  const messaging = getMessaging(firebaseApp);

  const permission = await Notification.requestPermission();

  if (permission === 'granted') {
    const currentToken = await getToken(messaging);
    if (currentToken) {
      return currentToken;
    } else {
      throw new Error(
        'No registration token available. Request permission to generate one.'
      );
    }
  }

  return null;
};

export default retrievePushToken;
