import { gql } from '@apollo/client';

import type { GraphqlErrorType } from 'application/graphql/graphql-types';

export interface SigninClientMutation {
  signinClient:
    | {
        __typename: 'SigninClient';
        needTwoFactorConfirm: false;
        client: {
          id: string;
        };
      }
    | {
        __typename: 'SigninClient';
        needTwoFactorConfirm: true;
        confirmState: string;
      }
    | GraphqlErrorType;
}

export interface SigninClientMutationVars {
  email: string;
  password: string;
  deviceId: string;
  uniqId: string;
}

const SIGNIN_CLIENT = gql`
  mutation SigninClient(
    $email: String!
    $password: String!
    $deviceId: String
    $uniqId: String
  ) {
    signinClient(
      email: $email
      password: $password
      deviceId: $deviceId
      uniqId: $uniqId
    ) {
      __typename
      ... on SigninClient {
        client {
          id
        }
        needTwoFactorConfirm
        confirmState
      }
      ... on Error {
        error
        warnings {
          key
          warning
        }
      }
    }
  }
`;

export default SIGNIN_CLIENT;
