import React, { forwardRef, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import config from 'application/config';
import { getLanguage } from 'application/utils/get-language';

import css from './recaptcha.module.scss';
import useWindowDimensions from '../../application/hooks/use-window-dimensions';

interface ReCaptchaProps {
  error?: string;
}

const ReCaptcha = forwardRef<ReCAPTCHA, ReCaptchaProps>(({ error }, ref) => {
  const localRecaptchaRef = useRef<ReCAPTCHA>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowDimensions(); // Используем хук
  const [scale, setScale] = useState<number>(1);

  useEffect(() => {
    const resizeCaptcha = () => {
      const reCaptchaWidth = 302;
      const containerWidth =
        containerRef.current?.offsetWidth || reCaptchaWidth;

      if (containerWidth < reCaptchaWidth) {
        const scaleFactor = containerWidth / reCaptchaWidth;
        setScale(scaleFactor);
      } else {
        setScale(1);
      }
    };

    resizeCaptcha();

    window.addEventListener('resize', resizeCaptcha);

    return () => {
      window.removeEventListener('resize', resizeCaptcha);
    };
  }, [windowWidth]);

  return (
    <div ref={containerRef} className={css.RecaptchaWrapper}>
      <div
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'left top',
        }}
      >
        <ReCAPTCHA
          hl={getLanguage()}
          ref={ref || localRecaptchaRef}
          sitekey={config.recaptchKey}
        />
      </div>
      {error && <div className={css.Error}>{error}</div>}
    </div>
  );
});

ReCaptcha.displayName = 'ReCaptcha';

export default ReCaptcha;
