import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

import Input from 'ui/input';
import Slider from 'ui/slider';

import css from './slider-input.module.scss';

interface SliderInputProps {
  value: string;
  min: number;
  max: number;
  onChange: (value: string) => void;
}

const SliderInput: React.FC<SliderInputProps> = ({
  value,
  min,
  max,
  onChange,
}) => {
  if (min > max) {
    [max, min] = [min, max]; // change values
  }

  const [sliderValue, setSliderValue] = useState(
    (parseFloat(value || '0') / max) * 100
  );

  const disabled = useMemo(() => min === max && min === 0, [min, max]);

  useEffect(() => {
    const nextValue = (parseFloat(value || '0') / max) * 100;
    setSliderValue(isNaN(nextValue) ? 0 : nextValue);
  }, [value]);

  return (
    <div>
      <Input
        disabled={disabled}
        className={css.CustomInput}
        inputMode="numeric"
        type={'number'}
        value={value}
        onFocus={(e) => {
          if (e.target.value === '0') onChange('');
        }}
        onChange={(inputValue) => {
          const newValue = inputValue.replace(/[^0-9\.]+/g, '');

          onChange(newValue);
        }}
      />
      <div className={css.Slider}>
        <Slider
          className={cx(disabled && css.CustomInput)}
          disabled={disabled}
          value={sliderValue}
          min={0}
          max={100}
          onChange={(value) => {
            setSliderValue(value);
            onChange(
              (Math.round(((max * value) / 100) * 100) / 100).toString()
            );
          }}
        />
      </div>
    </div>
  );
};

export default SliderInput;
