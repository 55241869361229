import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import RussianFlagIcon from 'ui/icons/russia-flag-icon.svg';
import UsaFlagIcon from 'ui/icons/usa-flag-icon.svg';

import AppAnalytic from './app-analytic';
import EventEmitter from './event-emitter';
import LocalStorage from './local-storage';

export interface LanguageType {
  value: string;
  label: string;
  icon: string | ReactNode;
}

class WebApp {
  readonly eventEmitter: EventEmitter = new EventEmitter();
  readonly analytic: AppAnalytic = new AppAnalytic();

  get router() {
    return {
      getHelp: () => '/help',
      getIndexPage: () => '/',
      getHowVote: () => '/how-vote',
      getGoogleAuth: (refCode?: string) =>
        `/auth/google${refCode ? `?refCode=${refCode}` : ''}`,
      getTopVoters: () => '/top-voters',
      getClientInfo: (clientId: string) => `/client-info/${clientId}`,
      getTournamnt: (tournamentId: string, opt: { eventId?: string } = {}) =>
        `/tournament/${tournamentId}`,
      getCabinet: () => '/user/cabinet',
      getAffiliate: () => '/user/affiliate-program',
      getSingIn: () => '/signin',
      getSingUp: () => '/signup',
      getBalance: () => '/user/balance',
      getCalendarOfEvents: () => '/events-calendar',
      getSport: (sportAlias: string) => `/${sportAlias}`,
      getComment: (data: {
        commentId?: string | null;
        eventId: string | null;
        tournamentId: string | null;
        newsId: string | null;
      }) => {
        const { commentId, newsId, tournamentId, eventId } = data;

        if (newsId) {
          return `/news/${newsId}`;
        }

        return `/tournament/${tournamentId}`;
      },
      getNewsComment: (newsId: string, commentId?: string) => `/news/${newsId}`,
      getTournamentComment: (
        tournamentId: string,
        eventId: string | null,
        commentId?: string | null
      ) => `/tournament/${tournamentId}`,
    };
  }

  get log() {
    return {
      exception: console.error,
    };
  }

  get localStorage() {
    return LocalStorage;
  }

  get appLanguages() {
    return [
      {
        value: 'ru',
        label: 'RU',
        icon: <RussianFlagIcon />,
      },
      {
        value: 'en',
        label: 'EN',
        icon: <UsaFlagIcon />,
      },
    ];
  }

  get isCoeffLive() {
    return true;
  }

  public async getDeviceIds() {
    let deviceId = LocalStorage.get('deviceId');
    if (!deviceId) {
      deviceId = uuidv4();
      LocalStorage.set('deviceId', deviceId);
    }
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();

    return {
      deviceId,
      uniqId: result.visitorId,
    };
  }
}

export default new WebApp();
