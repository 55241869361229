import { IncomingHttpHeaders } from 'http';

import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import merge from 'deepmerge';
import { createClient } from 'graphql-ws';
import isEqual from 'lodash/isEqual';
import type { AppProps } from 'next/app';
import { useMemo } from 'react';

import { getLanguage } from '../utils/get-language';

type InitialState = NormalizedCacheObject | undefined;

interface IInitializeApollo {
  headers?: IncomingHttpHeaders | null;
  initialState?: InitialState | null;
}

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

const createApolloClient = (
  urls: { apiUrl: string; wssUrl: string },
  headers: IncomingHttpHeaders | null = null
) => {
  const { apiUrl, wssUrl } = urls;

  const enhancedFetch = (
    url: RequestInfo | URL,
    init?: RequestInit | undefined
  ) => {
    return fetch(url, {
      ...init,
      headers: {
        ...init?.headers,
        'Access-Control-Allow-Origin': '*',
        Cookie: headers?.cookie ?? '',
        'x-locale': getLanguage(headers?.cookie),
      },
    });
  };

  const httpLink = createUploadLink({
    uri: apiUrl,
    fetchOptions: {
      mode: 'cors',
    },
    credentials: 'include',
    fetch: enhancedFetch,
  });

  if (typeof window === 'undefined') {
    return new ApolloClient({
      ssrMode: true,
      link: httpLink,
      cache: new InMemoryCache(),
    });
  }

  const wsLink = new GraphQLWsLink(
    createClient({
      url: wssUrl,
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    ssrMode: false,
    link: splitLink,
    cache: new InMemoryCache(),
  });
};

export function initializeApollo(
  urls: { apiUrl: string; wssUrl: string },
  headers: IncomingHttpHeaders | null = null,
  initialState?: any
) {
  const _apolloClient = apolloClient ?? createApolloClient(urls, headers);

  if (initialState) {
    const existingCache = _apolloClient.cache.extract();

    const data = merge(existingCache, initialState, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    _apolloClient.cache.restore(data);
  }

  if (!apolloClient && typeof window !== 'undefined') {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export const initApolloClient = (
  urls: { apiUrl: string; wssUrl: string },
  { headers = null, initialState = null }: IInitializeApollo = {}
) => {
  return initializeApollo(urls, headers, initialState);
};

export const addApolloState = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  pageProps: AppProps['pageProps']
) => {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = apolloClient.cache.extract();
  }

  return pageProps;
};

export const useApollo = (
  urls: { apiUrl: string; wssUrl: string },
  pageProps: AppProps['pageProps']
) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(
    () => initApolloClient(urls, { initialState: state }),
    [urls, state]
  );

  return store;
};
