import Chart from 'chart.js/auto';
import { min } from 'moment';
import React, { useEffect, useRef, useState } from 'react';

interface LineChartProps {
  dates?: string[];
  data: {
    name: string;
    color: string;
    dataset: number[] | { x: number; y: number }[];
  }[];
  y?: { min?: number; max?: number };
}

const LineChart: React.FC<LineChartProps> = ({ dates, data, y }) => {
  const ref = useRef<HTMLCanvasElement | null>(null);
  const chart = useRef<Chart | null>(null);

  useEffect(() => {
    if (chart.current) {
      chart.current.data = {
        ...chart.current.data,
        labels: dates,
        datasets: data.map((item) => ({
          label: item.name,
          borderWidth: 1,
          borderColor: item.color,
          backgroundColor: item.color,
          data: item.dataset,
          fill: false,
          pointStyle: false,
        })),
      };

      chart.current.options.scales = {
        x: {
          grid: {
            display: true,
          },
        },
        y: {
          grid: {
            display: false,
          },
          ...(y?.min !== undefined && { min: y.min }),
          ...(y?.max !== undefined && { max: y.max }),
        },
      };

      chart.current.update();
    }
  }, [dates, data, y]);

  useEffect(() => {
    if (ref.current) {
      chart.current = new Chart(ref.current, {
        type: 'line',
        data: {
          labels: dates,
          datasets: data.map((item) => ({
            label: item.name,
            borderWidth: 1,
            borderColor: item.color,
            backgroundColor: item.color,
            data: item.dataset,
            fill: false,
            pointStyle: false,
          })),
        },
        options: {
          responsive: true,
          aspectRatio: 1,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: true,
              },
            },
            y: {
              grid: {
                display: false,
              },
              ...(y?.min !== undefined && { min: y.min }),
              ...(y?.max !== undefined && { max: y.max }),
            },
          },
        },
      });

      return () => {
        chart.current?.destroy();
        chart.current = null;
      };
    }
  }, [ref]);

  return (
    <div>
      <canvas ref={ref} />
    </div>
  );
};

export default LineChart;
